import { store } from '../../../../configuration/setup/store'
import { mileageValidationAction, resetErrorKeyAction, vinReceivedAction } from '../deviceRegistryActions'
import { selectMileage, selectRegistrationError, selectVin } from '../deviceRegistryReducer'
import { MileageValidator } from '../mileage.validator'
import { DeviceRegistryServices } from './deviceRegistryServices'
import { MESSAGE_TYPES } from './message.types'

export class FrameServices {
    static PARENT_MESSAGE_TYPE = 'message'
    static ALLOWED_PARENT_ORIGIN =
        process.env.NODE_ENV === 'production' ?
            [process.env.REACT_APP_ASSET_ADMINISTRATION_URL,
                process.env.REACT_APP_ASSET_ADMINISTRATION_CONNECTOR_URL] : [window.location.origin + '/']

    static registerMessageListener() {
        // @ts-ignore
        window.addEventListener(FrameServices.PARENT_MESSAGE_TYPE, FrameServices.receiveParentData)
    }

    public static async receiveParentData(
        message: any,
        reduxStore: any = store,
        registerService: Function = DeviceRegistryServices.sendRegistrationRequest): Promise<void> {
        if (!FrameServices.isValidParentMessage(message)) {
            return
        }
        try {
            switch (message.data.type) {
                case MESSAGE_TYPES.INITIAL_MESSAGE_TYPE:
                    FrameServices.handleInitialMessage(message, vinReceivedAction, reduxStore)
                    break
                case MESSAGE_TYPES.SUBMIT_MESSAGE_TYPE:
                    await FrameServices.handleSubmitMessage(registerService, reduxStore)
                    break
                default:
                    throw new Error(`Unknown message data type received: ${message.data.type}`)
            }
        } catch (e) {
            console.error(`receiveParentData::${e}`)
        }
    }

    private static isValidParentMessage(message: any): boolean {
        return message?.type === FrameServices.PARENT_MESSAGE_TYPE &&
            [MESSAGE_TYPES.INITIAL_MESSAGE_TYPE, MESSAGE_TYPES.SUBMIT_MESSAGE_TYPE].includes(message.data.type) &&
            FrameServices.ALLOWED_PARENT_ORIGIN.includes(FrameServices.getOrigin())
    }

    private static getOrigin(): string {
        return window.location !== window.parent.location ? document.referrer : document.location.href
    }

    private static handleInitialMessage(message: any, action: Function, reduxStore: any): void {
        const content = message?.data?.payload?.vin
        if (!content) {
            throw new Error(`Initial message found with unexpected format: ${JSON.stringify(message)}`)
        }
        reduxStore.dispatch(action(content))
    }

    private static async handleSubmitMessage(registerService: Function, reduxStore: any): Promise<void> {
        if (selectRegistrationError(reduxStore.getState()) !== '') {
            reduxStore.dispatch(resetErrorKeyAction())
        }
        const mileage = selectMileage(reduxStore.getState())
        const vin = selectVin(reduxStore.getState())
        if (mileage !== '' && MileageValidator.validateMileage(mileage)) {
            await registerService({vin, mileage})
        } else {
            reduxStore.dispatch(mileageValidationAction(false))
            FrameServices.sendRegistrationFailedToParent()
        }
    }

    public static sendHeightToParent(height: string) {
        FrameServices.sendMessageToParent(MESSAGE_TYPES.CONNECTOR_SPA_RESIZE_MESSAGE_TYPE, {height})
    }

    public static sendRegistrationFailedToParent() {
        FrameServices.sendMessageToParent(MESSAGE_TYPES.ERROR_MESSAGE_TYPE)
    }

    public static sendMessageToParent(type: MESSAGE_TYPES, payload: any = {}): void {
        const messageWithType = {
            type,
            payload,
        }
        FrameServices.ALLOWED_PARENT_ORIGIN.forEach((parent) => {
            window.parent.postMessage(messageWithType, parent!)
        })
    }
}
