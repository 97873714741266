export const VIN_RECEIVED_ACTION = 'app/VIN_RECEIVED_ACTION'
export const vinReceivedAction = (vin: string) => ({
    type: VIN_RECEIVED_ACTION,
    payload: vin,
})

export const MILEAGE_UPDATED_ACTION = 'app/MILEAGE_UPDATED_ACTION'
export const mileageUpdatedAction = (mileage: string) => ({
    type: MILEAGE_UPDATED_ACTION,
    payload: mileage,
})

export const DEVICE_REGISTRATION_FAILED_ACTION = 'app/DEVICE_REGISTRATION_FAILED_ACTION'
export const deviceRegistrationFailedAction = (errorMessageKey: string) => ({
    type: DEVICE_REGISTRATION_FAILED_ACTION,
    payload: errorMessageKey,
})

export const MILEAGE_VALIDATION_ACTION = 'app/MILEAGE_VALIDATION_ACTION'
export const mileageValidationAction = (isValid: boolean) => ({
    type: MILEAGE_VALIDATION_ACTION,
    payload: isValid,
})

export const RESET_ERROR_KEY_ACTION = 'app/RESET_ERROR_KEY_ACTION'
export const resetErrorKeyAction = () => ({
    type: RESET_ERROR_KEY_ACTION,
})
