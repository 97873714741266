export interface ConfigState {
    id: string | undefined
    login: {
        authority: string | undefined
        clientId: string | undefined
        oauthScope: string[]
        mockAuthorization: boolean
        mockLocale: string | undefined
        preventRedirect: boolean
        redirectUri: string | undefined
        silentRedirectUri: string | undefined
    }
    logoutUri: string | undefined
    sentryToken: string | undefined
    sentryModuleName: string
}

const getBoolEnvValue = (envValue: string): boolean => {
    return typeof process.env[envValue] !== 'undefined' && process.env[envValue] === 'true'
}

export const config: ConfigState = {
    id: process.env.REACT_APP_ID,
    login: {
        authority: process.env.REACT_APP_LOGIN_AUTHORITY,
        clientId: '81eb18ab-cdc8-4175-8b24-70aeff7e1301',
        oauthScope: ['openid', 'profile', 'email', 'vcp-connector.write'],
        mockAuthorization: getBoolEnvValue('REACT_APP_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: process.env.REACT_APP_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('REACT_APP_LOGIN_PREVENT_REDIRECT'),
        redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
        silentRedirectUri: process.env.REACT_APP_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: process.env.REACT_APP_LOGOUT_URI,
    sentryToken: process.env.REACT_APP_SENTRY_DSN,
    sentryModuleName: 'starterTemplate',
}
