import {
    DEVICE_REGISTRATION_FAILED_ACTION,
    MILEAGE_UPDATED_ACTION,
    MILEAGE_VALIDATION_ACTION,
    RESET_ERROR_KEY_ACTION,
    VIN_RECEIVED_ACTION
} from './deviceRegistryActions'

export const initialState = {
    vin: '',
    mileage: '',
    isMileageValid: true,
    errorKey: '',
}

const getUpdatedState = (state: any, key: any, value: any) => ({
    ...state,
    [key]: value,
})

const registrationReducer = (state = initialState, action: any = {}) => {
    switch (action?.type) {
        case VIN_RECEIVED_ACTION:
            return getUpdatedState(state, 'vin', action?.payload)
        case MILEAGE_UPDATED_ACTION:
            return getUpdatedState(state, 'mileage', action?.payload)
        case DEVICE_REGISTRATION_FAILED_ACTION:
            return getUpdatedState(state, 'errorKey', action?.payload)
        case MILEAGE_VALIDATION_ACTION:
            return getUpdatedState(state, 'isMileageValid', action?.payload)
        case RESET_ERROR_KEY_ACTION:
            return getUpdatedState(state, 'errorKey', '')
        default:
            return state
    }
}

export const selectVin = (state: any) => state?.registrations?.vin
export const selectMileage = (state: any) => state?.registrations?.mileage
export const selectRegistrationError = (state: any) => state?.registrations?.errorKey
export const selectIsMileageValid = (state: any) => state?.registrations?.isMileageValid
export const selectLanguageData = (state: any) => state?.lang?.displayMessages

export default registrationReducer
