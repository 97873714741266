export class MileageValidator {
    static validateMileage(mileage: string): boolean {
        const numericMileage = Number(mileage)
        return (
            !isNaN(numericMileage) &&
            Number.isInteger(numericMileage) &&
            mileage.slice(-1) !== '.' &&
            numericMileage >= 0
        )
    }
}
