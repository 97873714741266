import { store } from '../../../../configuration/setup/store'
import { HttpService } from './httpServices'
import { FrameServices } from './frameServices'
import { deviceRegistrationFailedAction, resetErrorKeyAction } from '../deviceRegistryActions'
import { selectRegistrationError } from '../deviceRegistryReducer'
import { MESSAGE_TYPES } from './message.types'

    const REGISTRATION_FAIL_UNKNOWN = 'registration.error.unknown'
    const REGISTRATION_FAIL_NO_DEVICE_FOUND = 'registration.error.no.device'
    const REGISTRATION_FAIL_DIFFERENT_MILEAGE = 'registration.error.different.mileage'
    const REGISTRATION_FAIL_DIFFERENT_MILEAGE_TOO_MANY_ATTEMPTS = 'registration.error.different' +
        '.mileage.too.many.attempts'
    const REGISTRATION_FAIL_NO_MILEAGE_FOR_ORIGINATOR = 'registration.error.no.mileage.for.originator'
    const REGISTRATION_FAIL_EMPTY_RESULT = 'registration.error.empty.result'
    const VCP_REGISTRY_BASE_URL = process.env.REACT_APP_VPC_REGISTRY_BASE_URL
    const DEVICE_REGISTRATIONS_URL = `${VCP_REGISTRY_BASE_URL}/device-registrations`
export class DeviceRegistryServices {


    static async sendRegistrationRequest(data: any, postRequest: Function = HttpService.post, reduxStore: any = store) {
        try {
            const response = await postRequest(data, DEVICE_REGISTRATIONS_URL)
            if (response.status === 200 || response.status === 404) {
                DeviceRegistryServices.handleResponse(response, reduxStore)
            } else {
                let errorMessage = ''
                if(response.body.message.includes('No vehicle or TBM3 device found')){
                    errorMessage = REGISTRATION_FAIL_NO_DEVICE_FOUND
                }
                else if(response.body.message.includes('Input mileage') &&
                response.body.message.includes('differed from saved mileage')){
                    errorMessage = REGISTRATION_FAIL_DIFFERENT_MILEAGE
                }
                else if(response.body.message.includes('No mileage found for originator')){
                    errorMessage = REGISTRATION_FAIL_NO_MILEAGE_FOR_ORIGINATOR
                }
                else if(response.body.message.includes('Detected a big amount of failed registration attempts ' +
                    'with wrong mileage')){
                    errorMessage = REGISTRATION_FAIL_DIFFERENT_MILEAGE_TOO_MANY_ATTEMPTS
                }
                else {
                    errorMessage = REGISTRATION_FAIL_UNKNOWN
                }
                DeviceRegistryServices.handleError(reduxStore, errorMessage)
            }
        } catch (e) {
            DeviceRegistryServices.handleError(reduxStore, REGISTRATION_FAIL_UNKNOWN)
        }
    }

    private static handleError(reduxStore: any, errorMessage: string) {
        reduxStore.dispatch(deviceRegistrationFailedAction(errorMessage))
        FrameServices.sendRegistrationFailedToParent()
    }

    private static handleResponse(response: any, reduxStore: any) {
        if (response.status === 404) {
            console.warn('sendRegistrationRequest::received 404')
            DeviceRegistryServices.handleError(reduxStore, REGISTRATION_FAIL_EMPTY_RESULT)
        } else {
            if (selectRegistrationError(reduxStore.getState()) !== '') {
                reduxStore.dispatch(resetErrorKeyAction())
            }
            FrameServices.sendMessageToParent(MESSAGE_TYPES.SUCCESS_MESSAGE_TYPE, response.body)
        }
    }
}
