import './App.css'

import { IntlProvider } from 'react-intl'

import { DEFAULT_LOCALE } from '../../configuration/lang/lang'
import { useAppSelector } from '../../configuration/setup/hooks'
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice'

import { DeviceRegistry } from './device-registry/DeviceRegistry'
import { mileageUpdatedAction, mileageValidationAction } from './device-registry/deviceRegistryActions'
import {
    selectIsMileageValid,
    selectLanguageData,
    selectRegistrationError,
    selectVin
} from './device-registry/deviceRegistryReducer'
import { connect } from 'react-redux'

const AppContainer = (props: any) => {

    const userLocale = useAppSelector(getLocale)
    const displayMessages = useAppSelector(getDisplayMessages)

    if (!displayMessages || !userLocale) {
        return null
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <DeviceRegistry {...props}/>
        </IntlProvider>
    )
}

export const mapDispatchToProps = (dispatch: Function) => ({
    updateMileage: (mileage: string) => dispatch(mileageUpdatedAction(mileage)),
    setMileageValid: (valid: boolean) => dispatch(mileageValidationAction(valid)),
})

export const mapStateToProps = (state: any) => {
    return {
        vin: selectVin(state),
        isMileageValid: selectIsMileageValid(state),
        errorKey: selectRegistrationError(state),
        languageData: selectLanguageData(state),
        userLocale: getLocale(state),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
