import React, { Component } from 'react'
import { FrameServices } from './services/frameServices'
import { MileageValidator } from './mileage.validator'
import { FormattedMessage } from 'react-intl'
import { ClearableInput } from '@rio-cloud/rio-uikit'
import { MESSAGE_TYPES } from './services/message.types'

export class DeviceRegistry extends Component<DeviceRegistryProps, DeviceRegistryState> {

    private readonly modalBody: React.RefObject<any>
    private readonly mileageRef: React.RefObject<any>

    constructor(props: DeviceRegistryProps) {
        super(props)
        this.state = {mileage: '', height: '', errorKey: '', isMileageValid: false, languageData: {}, vin: ''}
        this.modalBody = React.createRef()
        this.mileageRef = React.createRef()
        this.onMileageUpdated = this.onMileageUpdated.bind(this)
        this.reportSize = this.reportSize.bind(this)
    }

    private reportSize(): void {
        const boundingBox = this.modalBody.current && this.modalBody.current.getBoundingClientRect()
        // height of the body is sent to the parent, so it can be resized within the modal
        const clientHeight = boundingBox && boundingBox.height
        if (clientHeight !== this.state.height) {
            this.setState({height: clientHeight})
            FrameServices.sendHeightToParent(clientHeight)
        }
    }

    componentDidMount(): void {
        FrameServices.registerMessageListener()
        FrameServices.sendMessageToParent(MESSAGE_TYPES.CONNECTOR_SPA_READY)
        this.reportSize()
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        this.reportSize()
        if (this.props.errorKey) {
            this.mileageRef.current.focus()
        }
    }

    private onMileageUpdated(newValue: string) {
        const isValid = MileageValidator.validateMileage(newValue)
        this.props.setMileageValid(isValid)
        if (isValid) {
            this.setState({mileage: newValue})
            this.props.updateMileage(newValue)
        }
    }

    private renderMileageInput() {
        const {mileage} = this.state
        const formGroupStyle = this.props.isMileageValid ? {marginBottom: 0} : {paddingBottom: 30, marginBottom: 0}
        const formGroupClassName = `form-group ${!this.props.isMileageValid ? 'has-error has-feedback' : ''}`
        return (
            <div id={MILEAGE_FORM_GROUP_ID} style={formGroupStyle} className={formGroupClassName}>
                <label>
                    <FormattedMessage id={MILEAGE_LABEL_MSG_ID}/>
                </label>
                <ClearableInput value={mileage}
                                onChange={this.onMileageUpdated}
                                inputRef={this.mileageRef}/>
                {!this.props.isMileageValid &&
                    <span className={'help-block'}>
                        <FormattedMessage id={MILEAGE_VALIDATION_MSG_ID}/>
                    </span>
                }
            </div>
        )
    }

    private renderExplainText(): React.ReactNode {
        return (
            <div id={EXPLAIN_TEXT_GROUP_ID} className='padding-bottom-25'>
                <span>
                    <FormattedMessage
                        id={EXPLAIN_TEXT_MSG_ID}
                        values={{vin: <b>{this.props.vin}</b>}}/>
                </span>
            </div>
        )
    }

    private renderErrorMessage(): React.ReactNode {
        return this.props.errorKey &&
            <div className='alert alert-danger margin-top-25 margin-bottom-0'>
                <span>
                    <FormattedMessage id={this.props.errorKey}/>
                </span>
            </div>
    }

    render(): React.ReactNode {
        return (
            <div ref={this.modalBody}>
                {this.renderExplainText()}
                {this.renderMileageInput()}
                {this.renderErrorMessage()}
            </div>
        )
    }
}

export interface DeviceRegistryState {
    vin: string
    mileage: string
    isMileageValid: boolean
    errorKey: string
    languageData: Object
    height: string
}
export interface DeviceRegistryProps extends DeviceRegistryState {
    updateMileage: Function
    setMileageValid: Function
}

export const MILEAGE_FORM_GROUP_ID = 'mileage-form-group-id'
export const EXPLAIN_TEXT_GROUP_ID = 'explain-text-group-id'
export const MILEAGE_LABEL_MSG_ID = 'registration.input.label.mileage'
export const MILEAGE_VALIDATION_MSG_ID = 'registration.input.validation.error.mileage'
export const EXPLAIN_TEXT_MSG_ID = 'registration.input.explain.text'
